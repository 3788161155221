import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
      width: 256
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)'
    },
    avatar: {
      cursor: 'pointer',
      width: 80,
      height: 80,
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    nested: {
      paddingLeft: theme.spacing(6),
      height: 40
    },
    listItem: {
      height: 46,
      color: '#000',
      cursor: 'pointer'
    },
    activeListItem: {
      backgroundColor: '#f5f5f5', // or any color you prefer
    },
    activeNestedItem: {
      backgroundColor: '#e0e0e0', // Slightly different for nested
      '& .MuiListItemText-primary': { // Target the text color in the nested item
        fontWeight: 'bold',  // Make the text bold when active
      },
    },
  }));