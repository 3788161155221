import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItem,
  Typography,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';
import * as Icons from '@mui/icons-material';
import { useStyles } from './Navbar.styles';
import { LocalStorage } from '../../Services/LocalStorage/Localstorage.service';
import NavUserDetails from './NavUserDetails';
import { AuthContext } from '../../Context/AuthContext';
import { sidebarItems } from './items';

const NavBar = ({ onMobileClose, openMobile }) => {

  const classes = useStyles();
  const [openSections, setOpenSections] = useState({});
  const history = useHistory();
  const [, setLoginData] = useContext(AuthContext);
  const role = LocalStorage.get('adminData') ? JSON.parse(LocalStorage.get('adminData')).role : '';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [openMobile, onMobileClose]);

  const handleLogout = () => {
    LocalStorage.clear();
    setLoginData({});
  };

  const handleSectionClick = (sectionId) => {
    setOpenSections(prev => ({ ...prev, [sectionId]: !prev[sectionId] }));
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        p={2}
      >
        <NavUserDetails />
      </Box>
      <Divider />
      <Box p={2}>
        {sidebarItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.roles.includes(role) && (
              <>
                <ListItemButton
                  onClick={() => (item.nestedItems ? handleSectionClick(index) : history.push(item.path))}
                  activeClassName={classes.activeListItem}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                    {React.createElement(Icons[item.icon], {
                      sx: { color: 'text.primary', fontSize: 20 }, // Darker color & smaller icon
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      variant: 'body2', // Smaller text 
                      color: 'text.primary' // Darker color
                    }}
                  />
                  {item.nestedItems && (openSections[index] ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />)}
                </ListItemButton>
                {item.nestedItems && (
                  <Collapse in={openSections[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.nestedItems.map((nestedItem, nestedIndex) => (
                        <ListItemButton
                          key={nestedIndex}
                          sx={{ pl: 4 }}
                          onClick={() => history.push(nestedItem.path)}
                          activeClassName={classes.activeNestedItem}
                        >
                          <ListItemText
                            primary={nestedItem.text}
                            primaryTypographyProps={{
                              variant: 'body2', // Smaller text 
                              color: 'text.primary' // Darker color
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </Box>
      {/* ... (Logout for mobile view) */}
      {
        openMobile ?
          <React.Fragment>
            <Divider />
            <ListItem onClick={handleLogout} className={classes.listItem} button>
              <Icons.Logout className={classes.icon} size={20} />
              <Typography variant="body2">Logout</Typography>
            </ListItem>
          </React.Fragment> : ''
      }
    </Box>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;