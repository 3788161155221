import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { useStyles } from './Navbar.styles';
import { LocalStorage } from '../../Services/LocalStorage/Localstorage.service';
import peyalaLogo from '../../assets/images/peyala_logo.png';

const NavUserDetails = () => {
  const classes = useStyles();
  const adminData = JSON.parse(LocalStorage.get('adminData')) || {};  // Safely parse, handle missing data

  const getRoleDisplay = (role, branchName) => {
    switch (role) {
      case 'super':
        return 'Super Admin';
      case 'operation':
        return 'Operation Admin';
      case 'branding':
        return 'Branding';
      case 'branch':
        return `${branchName} Branch`;
      default:
        return 'Unknown Role'; 
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center"> 
      <Avatar className={classes.avatar} src={peyalaLogo} alt="Peyala Logo" />
      <Grid item xs={12} textAlign="center" mt={2}> 
        <Typography className={classes.name} variant="h4" color="textPrimary">
          {adminData.name}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {getRoleDisplay(adminData.role, adminData.branch_name)}
        </Typography>
        <Typography variant="subtitle2">{adminData.country_name}</Typography>
      </Grid>
    </Grid>
  );
};

export default NavUserDetails;
