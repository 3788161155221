export const sidebarItems = [
    {
        text: 'Dashboard',
        icon: 'BarChartTwoTone',
        path: '/dashboard',
        roles: ['super', 'operation', 'branch']
    },
    {
        text: 'Orders',
        icon: 'ShoppingBagTwoTone',
        roles: ['super', 'operation', 'branch'],
        nestedItems: [
            { text: 'Orders Ongoing', path: '/orders-ongoing' },
            { text: 'Orders Scheduled', path: '/orders-scheduled' },
            { text: 'Orders Completed', path: '/orders-completed' },
            { text: 'Orders Cancelled', path: '/orders-cancelled' },
        ]
    },
    {
        text: 'Customers',
        icon: 'PeopleTwoTone',
        roles: ['super', 'branding'],
        nestedItems: [
            { text: 'Customer List', path: '/customer-list' },
            { text: 'Order History', path: '/customer-order-history' },
        ]
    },
    {
        text: 'Menu Categories',
        icon: 'FoodBankTwoTone',
        roles: ['super', 'branding', 'branch', 'operation'],
        nestedItems: [
            { text: 'Category List', path: '/category-list' },
            { text: 'Category Ordering', path: '/category-order' },
            { text: 'Suggested Items', path: '/suggested-menu' },
        ]
    },
    {
        text: 'Menu',
        icon: 'RestaurantMenuTwoTone',
        roles: ['super', 'branding', 'branch', 'operation'],
        nestedItems: [
            { text: 'Approved Menu List', path: '/approved-menu-list' },
            { text: 'Pending Menu List', path: '/pending-menu-list' },
            { text: 'Sizes', path: '/size-list' },
            { text: 'Addons', path: '/addons-list' },
            { text: 'Sauces', path: '/sauces' },
        ]
    },
    {
        text: 'Branches',
        icon: 'StoreTwoTone',
        path: '/branch-list',
        roles: ['super', 'operation', 'branch']
    },
    {
        text: 'Delivery Charge Module',
        icon: 'DeliveryDiningTwoTone',
        path: '/delivery-charge-module',
        roles: ['super', 'operation', 'branch']
    },
    {
        text: 'Store Time',
        icon: 'AccessTimeTwoTone',
        path: '/store-time-settings',
        roles: ['super', 'operation', 'branch']
    },
    {
        text: 'Vouchers',
        icon: 'RedeemTwoTone',
        // path: '/voucher-list',
        roles: ['super', 'operation', 'branch'],
        nestedItems: [
            { text: 'Approved Voucher List', path: '/approved-voucher-list' },
            { text: 'Pending Voucher List', path: '/pending-voucher-list' },
        ]
    },
    {
        text: 'Banners',
        icon: 'AnnouncementTwoTone',
        path: '/banner-list',
        roles: ['super', 'operation', 'branch']
    },
    {
        text: 'Content',
        icon: 'SourceTwoTone',
        roles: ['super', 'branding', 'branch', 'operation'],
        nestedItems: [
            { text: 'FAQ', path: '/faq-list' },
            { text: 'About Us', path: '/about-us-list' },
        ]
    },
    {
        text: 'Settings',
        icon: 'SettingsTwoTone',
        roles: ['super', 'branding', 'branch', 'operation'],
        nestedItems: [
            { text: 'Change Password', path: '/change-password' },
            // { text: 'Branch Settings', path: '/branch-settings' },
            { text: 'Country List', path: '/country-list' },
        ]
    },
    {
        text: 'Admins',
        icon: 'AccountCircleTwoTone',
        path: '/admin-list',
        roles: ['super', 'operation', 'branch']
    },
]; 