import { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import AuthRoutes from "./Routes/AuthRoutes";
import AppRoutes from "./Routes/AppRoutes";
import { AuthContext } from "./Context/AuthContext";
import Layout from "./Layouts";
import GlobalStyles from "./Components/GlobalStyles";
import theme from "./theme";

import "./App.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {

  const [loginData] = useContext(AuthContext);

  let routes = AuthRoutes;
  let authed = loginData.email;

  if (authed) {
    routes = <Layout>{AppRoutes}</Layout>;
  } else {
    routes = AuthRoutes;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <GlobalStyles />
          {routes}
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
